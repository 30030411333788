// src/log/types.ts
var LogType = {
  debug: "debug",
  info: "info",
  log: "log",
  warn: "warn",
  error: "error"
};

// src/log/index.ts
function useLog() {
  const localConfig = { prefix: "" };
  function setConfig(enable, prefix) {
    config.enabled = enable;
    config.prefix = prefix;
  }
  function setLocalConfig(prefix) {
    localConfig.prefix = prefix;
  }
  function log(message, logType = LogType.info) {
    if (!config.enabled)
      return;
    const messageFormatted = getColoredPrefix(logType) + " " + message + "\n";
    if (logType === LogType.error) {
      process.stderr.write(messageFormatted);
    } else {
      process.stdout.write(messageFormatted);
    }
  }
  function getColoredPrefix(logType) {
    const colors = {
      debug: [44, 49],
      info: [44, 49],
      log: [42, 49],
      warn: [43, 49],
      error: [41, 49]
    };
    return "\x1B[" + colors[logType][0] + "m[" + (localConfig.prefix || config.prefix) + "]\x1B[" + colors[logType][1] + "m";
  }
  return {
    setConfig,
    setLocalConfig,
    log
  };
}
var config = { enabled: false, prefix: "" };

// src/cache/cache.class.ts
class CacheClass {
  logObj = useLog();
  static instance;
  validityTime = 1800000;
  state = {
    state: StateOption.idle,
    stateTime: Date.now()
  };
  store = new Map;
  constructor() {
  }
  initLog(prefix, enableLog = false) {
    this.logObj.setConfig(enableLog, prefix);
  }
  setValidityTime(timeout) {
    this.validityTime = timeout * 1000;
    this.logObj.log("validityTime " + timeout + "s");
  }
  static getInstance() {
    if (!CacheClass.instance) {
      CacheClass.instance = new CacheClass;
    }
    return CacheClass.instance;
  }
  get(key) {
    if (!this.has(key))
      return;
    return this.store.get(key)?.data;
  }
  has(key) {
    return !!this.store.get(key);
  }
  isValid(validKey) {
    const cacheObj = this.store.get(validKey);
    if (!cacheObj) {
      throw new Error("Invalid key");
    }
    const expiry = cacheObj.validUntil - Date.now();
    this.logObj.log("cache expires in " + expiry / 1000 + "s");
    return cacheObj.validUntil >= Date.now();
  }
  set(key, data) {
    this.store.set(key, {
      data,
      validUntil: Date.now() + this.validityTime
    });
    this.logObj.log("setting cache; expires in " + this.validityTime / 1000 + "s", LogType.log);
    this.setCacheState(StateOption.idle);
  }
  setCacheState(newState) {
    this.state.state = newState;
    this.state.stateTime = Date.now();
  }
  getCacheState() {
    return this.state.state;
  }
}
var StateOption = {
  idle: "idle",
  fetching: "fetching"
};
// src/env/index.ts
var validateEnv = (schema) => {
  const parsed = schema.safeParse(process.env);
  if (!parsed.success) {
    console.error("Missing or invalid environment variable%s:\n%s", parsed.error.errors.length > 1 ? "s" : "", parsed.error.errors.map((error) => `  ${error.path}: ${error.message}`).join("\n"));
    process.exit(1);
  }
  return Object.freeze(parsed.data);
};
// src/parser/index.ts
function camelize(obj) {
  if (Array.isArray(obj)) {
    return obj.map((item) => camelize(item));
  } else if (obj !== null && typeof obj === "object") {
    const newObj = Array.isArray(obj) ? [] : {};
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        const camelKey = key.replace(/^_+/, "").replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
        newObj[camelKey] = camelize(obj[key]);
      }
    }
    return newObj;
  }
  return obj;
}
function parseInteger(input, fallback = 0) {
  const res = Number.parseInt(input);
  if (Number.isNaN(res)) {
    return fallback;
  }
  return res;
}
// src/version/version.ts
var version = "24.13.5.1";

// src/version/utils.ts
function getVersion() {
  return {
    version
  };
}
export {
  validateEnv,
  useLog,
  parseInteger,
  getVersion,
  camelize,
  StateOption,
  LogType,
  CacheClass
};

//# debugId=FB1E673259392E6164756E2164756E21
