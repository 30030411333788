export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"hid":"description","name":"description","content":"Der österreichische Supermarkt in Ihrer Nähe! Jetzt die die faszinierende Welt der Lebensmittel, Aktionen, Informationen und vielen Gewinnspiele bei SPAR entdecken!"}],"link":[{"rel":"apple-touch-icon","type":"image/x-icon","href":"/favicon/apple-touch-icon-180x180.png"},{"rel":"icon","type":"image/png","href":"/favicon/favicon-32x32.png","sizes":"32x32"},{"rel":"icon","type":"image/png","href":"/favicon/favicon-16x16.png","sizes":"16x16"}],"style":[],"script":[{"src":"https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit"}],"noscript":[],"title":"SPAR - Der österreichische Supermarkt in Ihrer Nähe"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false