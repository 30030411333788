import { default as indexyRhJJDcA5AMeta } from "~/pages/content/index.vue?macro=true";
import { default as component_45stubp0ME1PLRWNMeta } from "/builds/ecom/shop-storefront/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as component_45stubp0ME1PLRWN } from "/builds/ecom/shop-storefront/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: indexyRhJJDcA5AMeta?.name ?? "contentpage",
    path: indexyRhJJDcA5AMeta?.path ?? "/:slug*",
    meta: indexyRhJJDcA5AMeta || {},
    alias: indexyRhJJDcA5AMeta?.alias || [],
    redirect: indexyRhJJDcA5AMeta?.redirect,
    component: () => import("~/pages/content/index.vue").then(m => m.default || m)
  },
  {
    name: component_45stubp0ME1PLRWNMeta?.name ?? undefined,
    path: component_45stubp0ME1PLRWNMeta?.path ?? "/sitemap.xml",
    meta: component_45stubp0ME1PLRWNMeta || {},
    alias: component_45stubp0ME1PLRWNMeta?.alias || [],
    redirect: component_45stubp0ME1PLRWNMeta?.redirect,
    component: component_45stubp0ME1PLRWN
  }
]