import revive_payload_client_YTGlmQWWT5 from "/builds/ecom/shop-storefront/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/builds/ecom/shop-storefront/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/builds/ecom/shop-storefront/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_fWYXZjzQhM from "/builds/ecom/shop-storefront/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_VTarsGAzgb from "/builds/ecom/shop-storefront/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/builds/ecom/shop-storefront/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_xKuw1km5sV from "/builds/ecom/shop-storefront/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/builds/ecom/shop-storefront/packages/frontend/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/builds/ecom/shop-storefront/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/builds/ecom/shop-storefront/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import index_EjArLbFGJ8 from "/builds/ecom/shop-storefront/packages/frontend/src/plugins/fetch/index.ts";
import index_YPxaUxifOk from "/builds/ecom/shop-storefront/packages/frontend/src/plugins/contentstack/index.ts";
import index_GKimh7Hiwv from "/builds/ecom/shop-storefront/packages/frontend/src/plugins/translations/index.ts";
import index_NHEa44B3DF from "/builds/ecom/shop-storefront/packages/frontend/src/plugins/indexes/index.ts";
import index_CytMdX2eBr from "/builds/ecom/shop-storefront/packages/frontend/src/plugins/availability/index.ts";
import index_iAu6AJWWaV from "/builds/ecom/shop-storefront/packages/frontend/src/plugins/factfinder/index.ts";
import index_5IIKWhDTH1 from "/builds/ecom/shop-storefront/packages/frontend/src/plugins/mdsa/index.ts";
import index_oUzt1oHnHE from "/builds/ecom/shop-storefront/packages/frontend/src/plugins/ppc/index.ts";
import index_MPziwStMtB from "/builds/ecom/shop-storefront/packages/frontend/src/plugins/routes/index.ts";
import index_DNOlmuCSUx from "/builds/ecom/shop-storefront/packages/frontend/src/plugins/vue-final-modal/index.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  _0_siteConfig_fWYXZjzQhM,
  payload_client_VTarsGAzgb,
  check_outdated_build_client_LIYcCMJD18,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  chunk_reload_client_SeG0EjL5Ec,
  index_EjArLbFGJ8,
  index_YPxaUxifOk,
  index_GKimh7Hiwv,
  index_NHEa44B3DF,
  index_CytMdX2eBr,
  index_iAu6AJWWaV,
  index_5IIKWhDTH1,
  index_oUzt1oHnHE,
  index_MPziwStMtB,
  index_DNOlmuCSUx
]